import React from 'react';
import { graphql } from 'gatsby';
import { useIntl, Link } from 'gatsby-plugin-intl';

import Layout from '../layout';
import Footer from '../components/Footer/index';
import SEO from '../components/SEO';
import Heading from '../components/PressRelease/pageHeader';
import styles from './post.module.scss';

const PostTemplate = (props) => {
  const { data, pageContext } = props;
  const intl = useIntl();
  const { slug, nexttitle, nextslug, prevtitle, prevslug } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;
  if (!post.id) {
    post.id = slug;
  }

  return (
    <Layout>
      <div>
        <SEO
          meta={{
            title: `${post.title} | ${intl.formatMessage({
              id: 'pressRelease.press',
            })} ${intl.formatMessage({
              id: 'pressRelease.release',
            })} | ${intl.formatMessage({ id: 'name' })}`,
            desc: postNode.excerpt,
            slug: `/press-release${slug}`,
            image: post.cover.publicURL,
            datePublished: post.date,
            dateModified: post.modified,
          }}
          blog
        />

        <Heading noh />
        <div className="site-wrap">
          <div className="site-container">
            <div className="site-content site-content-aside site-content-posts">
              <div className="articles articles-full">
                <article className="details">
                  <h1>{post.title}</h1>
                  {post.cover ? (
                    <img src={post.cover.publicURL} alt={post.title} />
                  ) : null}
                  <p className={styles.postMeta}>
                    {intl.formatMessage({ id: 'pressRelease.publishedOn' })}{' '}
                    {post.date}
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
                  <div className={styles.postMeta} />
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer>
        <nav>
          <ol className="pagination pagination-full">
            <li>
              <Link to={prevslug} rel="prev">
                ← {prevtitle}
              </Link>
            </li>
            <li>
              <Link to={nextslug} rel="next">
                {nexttitle} →
              </Link>
            </li>
          </ol>
        </nav>
      </Footer>
    </Layout>
  );
};

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PressPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover {
          publicURL
        }
        date(formatString: "MMMM DD, YYYY")
        modified(formatString: "MMMM DD, YYYY")
      }
      fields {
        slug
      }
    }
  }
`;
export default PostTemplate;
